import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby"
import styled from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";

import { breakpoints } from "../styles/Breakpoints";
import LogoShort from "../images/svg/tide-and-tone-logo-short.svg";

const StickySection = styled.section`
	position: fixed;
	z-index: 10;
	width: calc(100% - 40px);
	box-shadow: 0px -5px 35px rgba(0, 0, 0, 0.25);
	transition: margin-top 0.6s;
	backdrop-filter: blur(10px);
	background-color: rgba(255, 255, 255, 0.75);
	max-width: 1200px;
    left: 0px;
    right: 0px;
	margin-left: auto;
	margin-right: auto;
    top: 15px;
    border-radius: 40px;
	.logo-button {
		padding: 0;
	}
`;

const Container = styled.div`
	max-width: 1150px;
	padding: 0 20px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: ${breakpoints.small}) {
		padding: 0 18px;
	}
	.site-logo {
		width: 140px;
		margin: 10px;
		@media (max-width: ${breakpoints.medSmall}) {
			width: 126px;
		}
	}
`;

const StickyButton = styled.button`
	color: var(--blue-dark);
	position: relative;
	padding: 0;
	margin-right: 44px;
	text-transform: uppercase;
	font-size: .875rem;
	@media (max-width: ${breakpoints.medSmall}) {
		margin-right: 16px;
	}
	&:hover {
		color: var(--blue-dark);
	}
	&:after {
		content: "";
		position: absolute;
		background-color: var(--blue-med-dark);
		height: 2px;
		top: 25px;
		left: 0;
		width: 0;
		transition: width 0.2s;
	}
	&:hover:after,
	&.current:after {
		width: 100%;
	}
`;

const RightItems = styled.div`
	display: flex;
	align-items: center;
	a.social {
		display: block;
		width: 22px;
		height: 22px;
		padding: 5px;
		margin: 0 0 0 12px;
		border-radius: 50%;
		border: transparent 2px solid;
		svg {
			fill: var(--blue-dark);
		}
		&:hover {
			background-color: var(--sand-med-light);
		}
		@media (max-width: ${breakpoints.medSmall}) {
			margin: 0 6px 0 0;
		}
		@media (max-width: ${breakpoints.small}) {
			display: none;
		}
	}
	a:nth-child(1):after {
		content: "";
		background-color: #000;
		width: 1px;
		position: absolute;
	}
	a:nth-child(3) {
		margin-left: 36px;
		@media (max-width: ${breakpoints.small}) {
			margin-left: 0;
		}
	}
`;

const StickyNav = (props) => {
	const [sticky, setSticky] = useState(props.alwaysSticky ? true : false);
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100 && sticky == false && props.alwaysSticky != true) {
				setSticky(true);
			} else if (window.scrollY < 100 && sticky == true && props.alwaysSticky != true) {
				setSticky(false);
			}
		};
		document.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			// clean up the event handler when the component unmounts
			document.removeEventListener("scroll", handleScroll);
		};
	}, [sticky]);
	function gaEvent(category, label) {
		if (window.gtag) {
			window.gtag("event", "click", {
				eventAction: "Sticky Nav Click",
				eventCategory: category,
				eventLabel: label,
			});
		}
	}
	return (
		<StickySection
			id="stick-nav"
			style={sticky ? { marginTop: "0" } : { marginTop: "-200px" }}
		>
			<Container>
				<Link
					aria-label="Back to Main Section"
					className="logo-button"
					to="/"
				>
					<LogoShort
						className="site-logo"
						alt="Tide &amp; Tone Photography"
					/>
				</Link>
				<RightItems>
					<StickyButton
						aria-label="Portfolio Section"
						className="mobile-hide"
						onClick={() => {
							navigate("/#portfolio")
						}}
					>
						Portfolio
					</StickyButton>
					<StickyButton
						aria-label="Pricing Section"
						className={props.currentPage === "pricing" ? "mobile-hide current" : "mobile-hide"}
						onClick={() => {
							navigate("/pricing")
						}}
					>
						Pricing
					</StickyButton>
					<StickyButton
						aria-label="Testimonials Section"
						className={props.currentPage === "testimonials" ? "mobile-hide current" : "mobile-hide"}
						onClick={() => {
							navigate("/testimonials")
						}}
					>
						Testimonials
					</StickyButton>
					<StickyButton
						aria-label="About Section"
						className={props.currentPage === "about" ? "mobile-hide current" : "mobile-hide"}
						onClick={() => {
							navigate("/about")
						}}
					>
						About
					</StickyButton>
					<StickyButton
						aria-label="Contact Section"
						className="mobile-hide"
						onClick={() => {
							scrollTo("#contact-section");
							if (window.gtag) {
								window.gtag("event", "contact_sticky", {
									eventAction: "Sticky Nav Click"
								});
							}
						}}
					>
						Contact
					</StickyButton>
					<a
						rel="noopener"
						href="https://www.instagram.com/tideandtonephoto/"
						target="_blank"
						className="social"
						onClick={() => gaEvent("External Link", "Instagram")}
					>
						<svg
							id="Layer_1"
							data-name="Layer 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<defs></defs>
							<path d="M12,2.16c3.2,0,3.58,0,4.85.07a6.67,6.67,0,0,1,2.23.42,3.61,3.61,0,0,1,1.38.89,3.61,3.61,0,0,1,.89,1.38,6.67,6.67,0,0,1,.42,2.23c.06,1.27.07,1.65.07,4.85s0,3.58-.07,4.85a6.67,6.67,0,0,1-.42,2.23,3.89,3.89,0,0,1-2.27,2.27,6.67,6.67,0,0,1-2.23.42c-1.27.06-1.65.07-4.85.07s-3.58,0-4.85-.07a6.67,6.67,0,0,1-2.23-.42,3.61,3.61,0,0,1-1.38-.89,3.61,3.61,0,0,1-.89-1.38,6.67,6.67,0,0,1-.42-2.23c-.06-1.27-.07-1.65-.07-4.85s0-3.58.07-4.85a6.67,6.67,0,0,1,.42-2.23,3.61,3.61,0,0,1,.89-1.38,3.61,3.61,0,0,1,1.38-.89,6.67,6.67,0,0,1,2.23-.42C8.42,2.17,8.8,2.16,12,2.16M12,0C8.74,0,8.33,0,7.05.07A8.76,8.76,0,0,0,4.14.63,6.07,6.07,0,0,0,.63,4.14,8.76,8.76,0,0,0,.07,7.05C0,8.33,0,8.74,0,12S0,15.67.07,17a8.76,8.76,0,0,0,.56,2.91,6.07,6.07,0,0,0,3.51,3.51,8.76,8.76,0,0,0,2.91.56C8.33,24,8.74,24,12,24s3.67,0,4.95-.07a8.76,8.76,0,0,0,2.91-.56,6.07,6.07,0,0,0,3.51-3.51A8.76,8.76,0,0,0,23.93,17C24,15.67,24,15.26,24,12s0-3.67-.07-4.95a8.76,8.76,0,0,0-.56-2.91A6.07,6.07,0,0,0,19.86.63,8.76,8.76,0,0,0,17,.07C15.67,0,15.26,0,12,0Zm0,5.84A6.16,6.16,0,1,0,18.16,12,6.16,6.16,0,0,0,12,5.84ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16ZM19.85,5.59a1.44,1.44,0,1,1-1.44-1.44A1.44,1.44,0,0,1,19.85,5.59Z" />
						</svg>
					</a>
					<a
						rel="noopener"
						href="https://www.facebook.com/tideandtone"
						target="_blank"
						className="social"
						onClick={() => gaEvent("External Link", "Facebook")}
					>
						<svg
							id="Layer_1"
							data-name="Layer 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<defs></defs>
							<path className="cls-1" d="M12.34,24h0Z" />
							<path className="cls-1" d="M11.86,24h0Z" />
							<path className="cls-1" d="M12.19,24h0Z" />
							<path d="M12,0a12,12,0,0,0-1.87,23.86V15.47H7.08V12h3.05V9.36c0-3,1.79-4.67,4.53-4.67a18.71,18.71,0,0,1,2.68.23v3H15.83a1.74,1.74,0,0,0-2,1.88V12H17.2l-.53,3.47h-2.8v8.39A12,12,0,0,0,12,0Z" />
							<path className="cls-1" d="M11.71,24h0Z" />
							<path className="cls-1" d="M13.41,23.92h0Z" />
							<path className="cls-1" d="M13.55,23.9l-.12,0Z" />
							<path className="cls-1" d="M12.7,24h0Z" />
							<path className="cls-1" d="M13.3,23.93l-.58.05Z" />
						</svg>
					</a>
				</RightItems>
			</Container>
		</StickySection>
	);
};

export default StickyNav;
