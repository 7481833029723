import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";
import Logo from "../images/svg/tide-and-tone-logo.svg";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const MobileNavContainer = styled.div`
	position: fixed;
	background: rgb(247,241,229);
	background: linear-gradient(0deg, rgba(247,241,229,1) 0%, rgba(231,242,232,1) 100%);
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 10;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s;
	&.show {
		opacity: 1;
		visibility: visible;
	}
	&.hide {
		opacity: 0;
		visibility: hidden;
	}
	.logo {
		width: 130px;
		margin-top: 60px;
	}
	ul {
		padding: 50px 0 0 0;
		li {
			list-style-type: none;
			a,
			button {
				color: var(--green);
				text-transform: uppercase;
				margin-bottom: 50px;
				font-size: 1.25rem;
				letter-spacing: 1px;
				text-decoration: none;
				display: block;
				text-align: center;
				width: 100%;
			}
		}
	}
	.social-container {
		display: flex;
		justify-content: center;
		a.social {
			display: block;
			width: 22px;
			height: 22px;
			padding: 5px;
			border-radius: 50%;
			border: transparent 2px solid;
			margin: 0 20px;
			&:nth-child(2) {
				margin-bottom: 0;
			}
			svg {
				fill: var(--green);
			}
			&:hover {
				border-color: var(--blue-light);
			}
		}
	}
`;

export default function MobileNav(props) {
	const [showNav, setShowNav] = useState(false);
	useEffect(() => {
		props.showMobileNav ? setShowNav(true) : setShowNav(false);
	});
	const handleClick = (contact) => {
		// Each need to be controled via higher order in Layout.js
		props.setShowMobileNav(false);
		props.setHamburgerOpen(false);
		if(contact & window.gtag) {
			window.gtag("event", "contact_mobile", {
				eventAction: "Contact Mobile"
			});
		}
	}
	return (
		<MobileNavContainer className={showNav ? "show" : "hide"}>
			<Logo className="logo" />
			<ul>
				<li>
					<Link
						to="/#portfolio"
						onClick={() => {
							handleClick();
						}}
					>
						Portfolio
					</Link>
				</li>
				<li>
					<Link
						to="/pricing"
						onClick={() => {
							handleClick();
						}}
					>
						Pricing &amp; What to Expect
					</Link>
				</li>
				<li>
					<Link
						to="/testimonials"
						onClick={() => {
							handleClick();
						}}
					>
						Testimonials
					</Link>
				</li>
				<li>
					<Link
						to="/about"
						onClick={() => {
							handleClick();
						}}
					>
						About
					</Link>
				</li>
				<li>
					<button
						aria-label="Contact Section"
						onClick={() => {
							handleClick('contact');
							scrollTo("#contact-section");
						}}
					>
						Contact
					</button>
				</li>
				<li>
					<div className="social-container">
						<a
							rel="noopener"
							href="https://www.instagram.com/tideandtonephoto/"
							target="_blank"
							className="social"
							// onClick={() =>
							// 	gaEvent("External Link", "Instagram")
							// }
						>
							<svg
								id="Layer_1"
								data-name="Layer 1"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
							>
								<defs></defs>
								<path d="M12,2.16c3.2,0,3.58,0,4.85.07a6.67,6.67,0,0,1,2.23.42,3.61,3.61,0,0,1,1.38.89,3.61,3.61,0,0,1,.89,1.38,6.67,6.67,0,0,1,.42,2.23c.06,1.27.07,1.65.07,4.85s0,3.58-.07,4.85a6.67,6.67,0,0,1-.42,2.23,3.89,3.89,0,0,1-2.27,2.27,6.67,6.67,0,0,1-2.23.42c-1.27.06-1.65.07-4.85.07s-3.58,0-4.85-.07a6.67,6.67,0,0,1-2.23-.42,3.61,3.61,0,0,1-1.38-.89,3.61,3.61,0,0,1-.89-1.38,6.67,6.67,0,0,1-.42-2.23c-.06-1.27-.07-1.65-.07-4.85s0-3.58.07-4.85a6.67,6.67,0,0,1,.42-2.23,3.61,3.61,0,0,1,.89-1.38,3.61,3.61,0,0,1,1.38-.89,6.67,6.67,0,0,1,2.23-.42C8.42,2.17,8.8,2.16,12,2.16M12,0C8.74,0,8.33,0,7.05.07A8.76,8.76,0,0,0,4.14.63,6.07,6.07,0,0,0,.63,4.14,8.76,8.76,0,0,0,.07,7.05C0,8.33,0,8.74,0,12S0,15.67.07,17a8.76,8.76,0,0,0,.56,2.91,6.07,6.07,0,0,0,3.51,3.51,8.76,8.76,0,0,0,2.91.56C8.33,24,8.74,24,12,24s3.67,0,4.95-.07a8.76,8.76,0,0,0,2.91-.56,6.07,6.07,0,0,0,3.51-3.51A8.76,8.76,0,0,0,23.93,17C24,15.67,24,15.26,24,12s0-3.67-.07-4.95a8.76,8.76,0,0,0-.56-2.91A6.07,6.07,0,0,0,19.86.63,8.76,8.76,0,0,0,17,.07C15.67,0,15.26,0,12,0Zm0,5.84A6.16,6.16,0,1,0,18.16,12,6.16,6.16,0,0,0,12,5.84ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16ZM19.85,5.59a1.44,1.44,0,1,1-1.44-1.44A1.44,1.44,0,0,1,19.85,5.59Z" />
							</svg>
						</a>
						<a
							rel="noopener"
							href="https://www.facebook.com/tideandtone"
							target="_blank"
							className="social"
							// onClick={() => gaEvent("External Link", "Facebook")}
						>
							<svg
								id="Layer_1"
								data-name="Layer 1"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
							>
								<defs></defs>
								<path className="cls-1" d="M12.34,24h0Z" />
								<path className="cls-1" d="M11.86,24h0Z" />
								<path className="cls-1" d="M12.19,24h0Z" />
								<path d="M12,0a12,12,0,0,0-1.87,23.86V15.47H7.08V12h3.05V9.36c0-3,1.79-4.67,4.53-4.67a18.71,18.71,0,0,1,2.68.23v3H15.83a1.74,1.74,0,0,0-2,1.88V12H17.2l-.53,3.47h-2.8v8.39A12,12,0,0,0,12,0Z" />
								<path className="cls-1" d="M11.71,24h0Z" />
								<path className="cls-1" d="M13.41,23.92h0Z" />
								<path className="cls-1" d="M13.55,23.9l-.12,0Z" />
								<path className="cls-1" d="M12.7,24h0Z" />
								<path className="cls-1" d="M13.3,23.93l-.58.05Z" />
							</svg>
						</a>
					</div>
				</li>
			</ul>
		</MobileNavContainer>
	);
}
