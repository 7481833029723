import React from "react";
import styled from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";

import { breakpoints } from "../styles/Breakpoints";

const FooterSection = styled.section`
	width: 100%;
	background-color: #fff;
	.contain {
		max-width: 1200px;
		margin: 0 auto;
		padding: 40px 20px;
		font-size: 12px;
		line-height: 1.4;
		color: var(--gray);
		text-transform: uppercase;
		letter-spacing: 1px;
		@media (max-width: ${breakpoints.small}) {
			letter-spacing: 0.4px;
		}
		span.spacer {
			display: inline-block;
			margin: 0 6px;
		}
		button {
			float: right;
			margin-top: -70px;
			svg {
				fill: var(--blue-med-dark);
				width: 20px;
			}
			&:hover svg {
				fill: var(--blue-dark);
			}
		}
		p.footer-note {
			text-transform: none;
			letter-spacing: normal;
			margin-top: 12px;
			color: var(--green);
		}
	}
`;

const Footer = () => {
	function gaEvent(category, label) {
		if (window.gtag) {
			window.gtag("event", "click", {
				eventAction: "Footer Click",
				eventCategory: category,
				eventLabel: label,
			});
		}
	}
	return (
		<FooterSection>
			<div className="contain">
				&copy; 2024 <span className="spacer">::</span> Tide &amp; Tone
				Photography <br /> Serving Southeastern North Carolina
				<p className="footer-note">Website designed, built, &amp; hosted by <a href="http://chadparkerdesign.com">Chad Parker</a>.</p>
				<button
					onClick={() => {
						scrollTo("#tide-and-tone");
						gaEvent("Navigate", "Back to Top");
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="31.65"
						height="44.86"
						viewBox="0 0 31.65 44.86"
					>
						<path
							className="a"
							d="M29.37 16.75a1.32 1.32 0 101.92-1.81h-.06L16.73.39a1.3 1.3 0 00-1.86 0L.36 14.89a1.32 1.32 0 001.86 1.86L15.8 3.18z"
						/>
						<path
							className="a"
							d="M17.12 43.57V1.4a1.32 1.32 0 10-2.63-.08v42.25a1.32 1.32 0 002.63 0z"
						/>
					</svg>
				</button>
			</div>
		</FooterSection>
	);
};

export default Footer;
