import React from "react";
import styled from "styled-components";
import Select from 'react-select'

const options = [
	{ value: 'organic', label: 'Web Search' },
	{ value: 'google-ad', label: 'Google Ad' },
	{ value: 'facebook', label: 'Facebook' },
	{ value: 'instagram', label: 'Instagram' },
	{ value: 'friend', label: 'From a Friend' }
  ]

const StyledForm = styled.form`
	label {
        font-size: 18px;
        position: absolute;
        transition: all 0.15s;
        color: var(--blue-dark);
        &.move {
            font-size: 14px;
            margin-top: -14px;
            color: var(--blue-med-dark);
        }
    }
    .select label {
        font-size: 16px;
        position: relative;
        transition: none;
        display: block;
        margin-bottom: 8px;
    }
    input {
        border: none;
        border-bottom: var(--blue-med-dark) 1px solid;
        padding: 12px 0;
        height: 20px;
        display: block;
        margin-bottom: 40px;
        width: 100%;
        font-size: 18px;
        color: var(--black);
        background-color: transparent;
        &:focus,
        &:active {
            outline: none;
            border-color: var(--blue-dark);
        }
        &.has-value {
            border-color: var(--blue-dark);
        }
        &.error {
            border-color: var(--orangeColor);
        }
    }
    textarea {
        display: block;
        background-color: transparent;
        border: none;
        border-bottom: var(--blue-med-dark) 1px solid;
        width: 100%;
        padding: 12px 0;
        font-size: 18px;
        line-height: 1.8;
        margin-bottom: 30px;
		color: var(--black);
        &:focus,
        &:active {
            outline: none;
            border-color: var(--blue-dark);
        }
        &.has-value {
            border-color: var(--blue-dark);
        }        
    }
    .select {
        margin-bottom: 30px;
    }
	button {
		margin-left: 0;
        padding: 12px 32px;
        border: var(--blue-med-dark) 1px solid;
        color: var(--blue-med-dark);
        &:hover {
            background-color: var(--blue-med-dark);
            color: #fff;
        }
	}
	.gotcha {
		display: none;
	}
`

const customStyles = {
	input: (provided, state) => ({
		...provided,
		border: state.isFocused ? 'none' : 'none',
		outline: state.isSelected ? 'none' : 'none',
		color: 'var(--black)'
	}),
	control: (provided, state) => ({
		...provided,
		borderColor: 'var(--green)',
		boxShadow: 'none'
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused ? 'var(--green-light)' : '#fff',
		color: state.isSelected ? 'var(--green)' : 'var(--black)',
	}),
}

export default function BasinForm() {
	function gaEvent(category, label) {
		if (window.gtag) {
			window.gtag("event", "form_submit", {
				eventAction: "Form Submit",
				eventCategory: category,
				eventLabel: label,
			});
		}
	}
	return (
		<StyledForm action="https://usebasin.com/f/6a7a4d8cb06d" method="post">
			<div className="text-field">
				<label htmlFor="firstName">First Name</label>
				<input
					id="firstName"
					className="input-field"
					name="firstName"
					type="text"
				/>
			</div>
			<div className="text-field">
				<label htmlFor="lastName">Last Name</label>
				<input
					id="lastName"
					className="input-field"
					name="lastName"
					type="text"
				/>
			</div>
			<div className="text-field">
				<label htmlFor="email">Email Address</label>
				<input
					id="email"
					className="input-field"
					name="email"
					type="email"
				/>
			</div>
			<div className="select">
				<label>How did you hear about Tide &amp; Tone?</label>
				<Select
					options={options}
					styles={customStyles}
					name="source"
					id="source"
					className={true}
				/>
			</div>
			<div className="text-field">
			<label htmlFor="comments">Comments</label>
			<textarea
				id="comments"
				className="input-field"
				name="comments"
				type="text"
			></textarea>
			<input className="gotcha" name="_gotcha"></input>
		</div>
			<button
				className="form-submit button primary"
				type="submit"
				onClick={() =>
					gaEvent("Contact Form Submit", "Contact Form Submit")
				}
			>
				Submit
			</button>
		</StyledForm>
	);
}
