import React from "react";
import { Helmet } from "react-helmet";

import openGraphImage from "../images/site-assets/tide-and-tone-open-graph.jpg";

const Header = ({location}) => {
	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: "en",
				}}
				bodyAttributes={{
					id: 'tide-and-tone',
					class: `${location === '/' ? 'homepage' : 'subpage'}`
				}}
			>
				<meta charset="UTF-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>
				<meta
					name="description"
					content="Lifestyle and Family Photography in Wilmington North Carolina."
				/>
				<meta
					name="keywords"
					content="Family Photography, Lifestyle Photography, Portraits, Engagement Photography, Couple's Photography, Expecting Baby Photography, Maternity Portraits, Maternaty Photography, Family Portraits, Real Estate Photography, Wilmington NC, Wilmington North Carolina, Chad Parker Photography"
				/>
				<meta name="author" content="Chad Parker" />
				<meta property="fb:app_id" content="3033376760241341" />
				<meta
					property="og:title"
					content="Tide &amp; Tone :: Photography by Chad Parker"
				/>
				<meta
					property="og:description"
					content="Family Photography, Portraits, Engagement Photography, Couple's Photography, Expecting Baby Photography, Maternity Portraits, Maternaty Photography, Family Portraits, Lifestyle Photography, Real Estate Photography, Wilmington NC, Wilmington North Carolina, Chad Parker Photography"
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://tideandtone.com/" />
				<meta
					property="og:image"
					content={`https://tideandtone.com${openGraphImage}`}
				/>
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="627" />
				<meta
					property="og:image:alt"
					content="Tide &amp; Tone Photography"
				/>
				<title>Tide &amp; Tone :: Photography by Chad Parker</title>
				<link
					rel="preconnect"
					href="https://fonts.gstatic.com"
					crossorigin
				/>
				<link
					media="print"
					onload="this.onload=null;this.removeAttribute('media');"
					href="https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Roboto:wght@300;400&display=swap"
					rel="stylesheet"
				/>
			</Helmet>
		</>
	);
};

export default Header;
