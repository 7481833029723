import { createGlobalStyle } from "styled-components";
import { breakpoints } from "./Breakpoints";

const GlobalStyles = createGlobalStyle`
    :root {
        --black: #727272;
        --blue: #527489;
        --orange: #B95E3E;
        --green: #6D8480;
        --green-light: #E7F2E8;
        --sand-dark: #727272;
        --sand-med: #ADA89B;
        --sand-light: #F7F1E5;
        --sand-extra-light: #F7F1E5;
        
        --gray: #6b6b6b;
        --gray-dark: #53525a;

        --blue-dark: #174460;
        --blue-med-dark: #36617C;
        --blue-light: #DFE9FD;
        
        --sand-med-dark: #bd8f6f;
        --sand-med-light: #e8d5c9;

        --bodoni: 'Bodoni Moda', serif;
        --roboto: 'Roboto', helvetica, arial, sans-serif;
    }
    body {
        font-family: var(--roboto);
        margin: 0;
        /* background-color: #ffffff; */
        font-size: 16px;
    }
    body.homepage {
        background-color: #8e9b9d;
    }
    body.homepage div#portfolio {
        background-color: #fff;
    }
    body.subpage {
        background-color: #fff;
    }
    h1 {
        font-family: var(--bodoni);
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    h2 {
        font-family: var(--bodoni);
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: .75px;
        line-height: 1.4;
        font-size: 2rem;
        color: var(--sand-dark);
        margin-top: 0;
        &.blue {
            color: var(--blue-dark);
        }
        @media(max-width: ${breakpoints.medium}) {
            font-size: 28px;
        }
    }
    p {
        margin: 0 0 1rem 0;
        line-height: 1.5;
        color: var(--black);
    }
    p a {
        color: var(--blue-dark);
    }
    button {
		-webkit-appearance: none;
		background: none;
		border: none;
		color: var(--sand-light);
		text-decoration: none;
		text-transform: uppercase;
		font-size: 14px;
		&:hover {
			color: #fff;
            cursor: pointer;
		}
	}
    .button {
        text-transform: uppercase;
        /* height: 18px; */
        padding: 16px 30px 16px;
        text-decoration: none;
        letter-spacing: .75px;
        font-size: .875rem;
        border-radius: 8px;
        font-weight: 400;
        width: auto;
        @media (max-width: 768px) {
            /* font-size: .75rem; */
        }
        &.center {
            margin: 0 auto;
            display: block;
        }
    }
    .button.primary {
        background-color: var(--blue-med-dark);
        color: #fff;
        &:hover {
            background-color: var(--blue-dark);
        }
        &.green {
            background-color: var(--green);
        }
    }
    .button.light {
        background: transparent;
        color: #fff;
        border: var(--blue-light) 1px solid;
        font-size: .8125rem;
        padding: 10px 20px;
        &:hover {
            background-color: var(--blue-dark);
            border-color: var(--blue-dark);
        }
        svg {
            fill: #fff;
        }
    }
    .button.icn-right {
        svg {
            width: 14px;
            margin-bottom: -5px;
            margin-left: 10px;
            margin-right: -4px;
            opacity: .75;
            transition: transform .2s;
            /* transform: rotate(90deg); */
        }
        &:hover {
            svg {
                transform: rotate(90deg);
                opacity: 1;
            }
        }
    }
    .button.slim {
        height: 12px;
        padding: 4px 16px 14px;
        letter-spacing: .5px;
        font-size: .7rem;
        line-height: 1;
        @media (max-width: 768px) {
            font-size: .7rem;
        }
    }
    .button.slim.icn-right {
        svg {
            width: 14px;
            margin-bottom: -5px;
            margin-left: 16px;
            margin-right: -2px;
            transition: transform .2s;
            opacity: .75;
            @media(max-width: ${breakpoints.small}) {
                margin-left: 8px;
            }
        }
    }
    .mobile-hide {
        display: inherit;
        @media(max-width: ${breakpoints.small}) {
            display: none!important;
        }
    }
    .mobile-show {
        display: none;
        @media(max-width: ${breakpoints.small}) {
            display: inherit;
        }
    }
    //Override react-image-lightbox toolbar style
    .ril__outer {
        background-color: rgba(255,255,255,.96);
    }
    .ril-toolbar {
        background: transparent!important;
    }
    .ril__closeButton,
    .ril__zoomOutButton,
    .ril__zoomInButton {
        filter: invert(60%) sepia(100%);
    }
    .ril__navButtons {
        padding: 34px 22px;
    }
    .ril__navButtonNext,
    .ril__navButtonPrev {
        background-color: rgba(0, 0, 0, .5);
        filter: invert(60%) sepia(1);

    }
`;

export default GlobalStyles;
