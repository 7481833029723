import React, { useState } from "react";
import Head from "./Head";
import Footer from "./Footer";
import StickyNav from "./StickyNav";
import MobileNav from "./MobileNav";
import MobileHamburger from "./MobileHamburger";
import Contact from "../components/Contact";
import GlobalStyles from "../styles/GlobalStyles";
import "normalize.css";

const Layout = ({location, alwaysSticky, currentPage, children }) => {
	const [showMobileNav, setShowMobileNav] = useState(false);
	const [hamburgerOpen, setHamburgerOpen] = useState(false);
	return (
		<>
			<GlobalStyles />
			<Head location={location} />
			<MobileHamburger
				setShowMobileNav={setShowMobileNav}
				setHamburgerOpen={setHamburgerOpen}
				hamburgerOpen={hamburgerOpen}
				alwaysBlue={alwaysSticky ? true : false}
			/>
			<StickyNav alwaysSticky={alwaysSticky} currentPage={currentPage} />
			<MobileNav
				showMobileNav={showMobileNav}
				setShowMobileNav={setShowMobileNav}
				setHamburgerOpen={setHamburgerOpen}
			/>
			{children}
			<Contact />			
			<Footer />
		</>
	);
};

export default Layout;

