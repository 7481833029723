import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Hamburger = styled.button`
	display: block;
	width: 24px;
	height: 20px;
	position: fixed;
	z-index: 11;
	top: 34px;
	right: 40px;
	padding: 0;
	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.2s ease-in-out;
		-moz-transition: 0.2s ease-in-out;
		-o-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out;
		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2),
		&:nth-child(3) {
			top: 8px;
		}
		&:nth-child(4) {
			bottom: 0;
		}
	}
	&.open span {
		background-color: var(--green) !important;
	}
	&.open span:nth-child(1) {
		top: 18px;
		width: 0%;
		left: 50%;
	}

	&.open span:nth-child(2) {
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&.open span:nth-child(3) {
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	&.open span:nth-child(4) {
		top: 18px;
		width: 0%;
		left: 50%;
	}
`;

export default function MobileHamburger(props) {
	const [fillColor, setFillColor] = useState(props.alwaysBlue ? "var(--green)" : "var(--sand-light)");
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 240 && 
				fillColor == "var(--sand-light)" &&
				props.alwaysBlue != true
			) {
				setFillColor("var(--green)");
			} else if (
				window.scrollY < 240 &&
				fillColor == "var(--green)" &&
				props.alwaysBlue != true
			) {
				setFillColor("var(--sand-light)");
			}
		};
		document.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			// clean up the event handler when the component unmounts
			document.removeEventListener("scroll", handleScroll);
		};
	}, [fillColor]);
	return (
		<div className="mobile-show">
			<Hamburger
				aria-label="Mobile Menu"
				onClick={() => {
					props.setHamburgerOpen(!props.hamburgerOpen);
					props.hamburgerOpen
						? props.setShowMobileNav(false)
						: props.setShowMobileNav(true);
				}}
				id="nav-icon"
				className={props.hamburgerOpen ? "open" : ""}
			>
				<span style={{ backgroundColor: fillColor }}></span>
				<span style={{ backgroundColor: fillColor }}></span>
				<span style={{ backgroundColor: fillColor }}></span>
				<span style={{ backgroundColor: fillColor }}></span>
			</Hamburger>
		</div>
	);
}
