import React from "react";
import styled from "styled-components";
import BasinForm from "./BasinForm";
import { breakpoints } from "../styles/breakpoints";

const ContactSection = styled.section`
	width: 100%;
	background-color: var(--sand-light);
	padding: 10px 0 40px;
	margin-top: 40px;
	@media (max-width: ${breakpoints.small}) {
		margin-top: 0;
		padding-top: 40px;
	}
	.container {
		max-width: 1000px;
		margin: 80px auto 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 0 30px;
		@media (max-width: ${breakpoints.small}) {
			display: inherit;
			margin-top: 40px;
		}
		.left-content {
			width: 40%;
			margin-right: 20px;
			@media (max-width: ${breakpoints.small}) {
				width: auto;
				margin-right: 0;
				margin-bottom: 80px;
			}
		}
		.right-content {
			width: 50%;
			@media (max-width: ${breakpoints.small}) {
				width: auto;
				margin-right: 0;
			}
		}
		.center-content {
			width: 80%;
			margin: 0 auto 50px;
			text-align: center;
		}
		.button {
			text-transform: none;
			font-size: 18px;
			display: block;
			padding: 10px 20px;
			background-color: var(--blue);
			color: #fff;
			max-width: 200px;
			margin: 40px auto 30px;
			&:hover {
				background-color: var(--blue-dark);
			}

		}
	}
`;

export default function Contact() {
	return (
		<ContactSection id="contact-section">
			<div className="container">
				<div className="left-content">
					<h2 className="blue">Now booking for summer &amp; fall 2024!</h2>
					<p>
						If you're ready to create moments to remember, or if you
						simply have some questions, please leave me a note here.
						Most messages are returned within a few hours.
					</p>
					{/* <a href="mailto:chad@tideandtone.com" className="button">
							Email Me
						</a> */}
					<p>I look forward to hearing from you!</p>
				</div>
				<div className="right-content">
					<BasinForm />
				</div>
			</div>
		</ContactSection>
	);
}
